.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  height: 48px;
  background-color: white;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #C6C6C6;
  padding: 14px 10px;
  cursor: text;
  .input {
    height: 100%;
    width: 100%;
    flex: 1;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: 'Inter';
    font-size: 14px;
    &::placeholder {
      color: #b3b3b3;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &:hover {
    background-color: #ffffffcc;
  }
  .rotate{
    animation: rotation 2s infinite linear;
  }
  .icon,
  .validIcon {
    display: flex;
    align-items: center;
    height: 24px;
    width: 24px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  &.invalid {
    border: 2px solid #a01235;
  }
  &.valid {
    border: 2px solid #67ce67;
  }
  &.disabled {
    cursor: default;
    .input {
      color: #b3b3b3;
    }
  }
  &.readOnly {
    cursor: default;
    .input {
      cursor: default;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}