@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
p,
ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  padding: 1rem;
  font-weight: 500;
}

.login {
  flex: 1;
  display: flex;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background-color: #F7EDEB;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  .grid {
    display: grid;
    grid-template-columns: auto auto;
    height: 100%;
    width: fit-content;
    align-self: center;

    .mainBox {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 30rem;
      align-items: center;
      gap: 0.8rem;
      background-color: #FFFFFF;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 6rem;
      padding-bottom: 10rem;
      border-style: solid;
      border-width: 1px;
      border-color: #CBC1C1;
      border-top-left-radius: 0rem;
      border-bottom-left-radius: 0rem;
      h1 {
        font-size: 30px;
        font-weight: 700;
        color: #000000;
      }
      h2 {
        font-size: 20px;
        font-weight: 400;
        color: #595959;
      }
      h3 {
        font-size: 12px;
        font-weight: 400;
        color: #595959;
        flex: none;
      }
      a {
        font-size: 12px;
        font-weight: 400;
        text-decoration-line: underline;
        text-underline-offset: 0.3rem;
        color: #000000;
        align-self: flex-end;
        flex: none;
        cursor: pointer;
      }
      img {
        height: 5rem;
        padding: 1rem;
        object-fit: contain;
      }
      button {
        margin-top: 1rem;
        color: #000000;
        border-color: #000000;
        background-color: #000000;
      }
      button:hover{
        background-color: #F7EDEB !important;
      }

      .actionBox {
        display: flex;
        flex-direction: column;
        width: fit-content;
        margin-top: 0.5rem;
        gap: 1rem;

        .link {
          width: 100%;
          margin: 0px;
          text-decoration-line: none;
        }

        .inputBox {
          display: flex;
          flex-direction: column;
          width: 20rem;

          .codeInput {
            letter-spacing: 1rem;
          }
        }

        .forgotPassword {
          margin-top: -0.5rem;
        }

        .resend {
          margin-top: -0.5rem;
        }

        .blocked {
          @extend .resend;
          color: #595959;
          text-decoration-line: none;
          cursor: default;
        }

        .messageBox {
          display: flex;
          flex-direction: column;
          gap: 0.8rem;

          .successBox {
            width: 20rem;
            padding: 1rem;
            font-size: 11px;
            color: #595959;
            background-color: #d2ffd2;
            border-radius: 4px;
          }

          .errorBox {
            width: 20rem;
            padding: 1rem;
            font-size: 11px;
            color: #595959;
            background-color: #ffd1dc;
            border-radius: 4px;
          }
        }

        .codeMethods {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 1rem;
        }

        .separatorBox {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          .separator {
            height: 1px;
            width: 100%;
            background-color: #CBC1C1;
          }
        }
      }
    }

    .imgLogin {
      display: flex;
      height: 100%;
      width: 100%;
      max-width: 50rem;
      overflow: hidden;
      img {
        height: 100%;
        border-top-right-radius: 0rem;
        border-bottom-right-radius: 0rem;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 1028px) {
  .login {
    .grid {
      .mainBox {
        border-top-right-radius: 0rem;
        border-bottom-right-radius: 0rem;
      }
      .imgLogin {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .login {
    padding: 3rem 0;
    .grid {
      .mainBox {
        width: 22rem;
        border-top-right-radius: 0rem;
        border-bottom-right-radius: 0rem;
        padding-top: 22px;
        h1{
          font-size: 22px ;
        }
        h2{
          text-align: center;
          font-size: 16px;
        }

        .actionBox {
          .inputBox {
            width: 18rem;
          }

          .messageBox {
            .successBox {
              width: 18rem;
            }
            .errorBox {
              width: 18rem;
            }
          }
        }
      }
      .imgLogin {
        display: none;
      }
    }
  }
}
