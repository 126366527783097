@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
p,
ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  padding: 1rem;
  font-weight: 500;
}


.default {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 1rem;
  outline: none;
  color: white !important;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 365px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;

  .label {
    position: relative;
    line-height: 100%;
  }

  .iconLeft {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: -32px;
  }

  .iconRight {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    right: -32px;
  }

  &.disabled {
    cursor: default;
    background-color: #C6C6C6!important;
  }
}

.outline {
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 1rem;
  outline: none;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 365px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  border-style: solid;
  border-width: 1px;

  .label {
    position: relative;
    line-height: 100%;
  }

  .iconLeft {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: -32px;
  }

  .iconRight {
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    right: -32px;
  }

  &:hover {
    background-color: #FFD1DC44;
  }

  &.disabled {
    cursor: default;
    border-color: #C6C6C6;
    background-color: transparent;
  }
}
